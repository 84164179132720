import { MOCK_ROLES } from "mocks/mock";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import ConfirmationDialogBox from "utils/ConfirmationDialogBox";
import { _delete, getAll, getImagesCount, add } from "./payouts.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { Toast } from "primereact/toast";

const Payouts = () => {
    const [payouts, setPayouts] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState("");
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [displayPayout, setDisplayPayout] = useState(false);
    const [payoutId, setPayoutId] = useState("");
    const toast = useRef();
    const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
    const [imagesCount, setImagesCount] = useState([]);
    const [totalImagesCount, setTotalImagesCount] = useState(0);
    const [values, setValues] = useState({ amount: null, reference_number: null, role_type: null, user_id: null, status: null });
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };
    const tableHeader = (
        <div className="table-header">
            {sessionInformation?.role_id === 1 ? "List of Payouts" : "My Payouts"}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const statusBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`customer-badge status-${data.status}`}>{data.status}</span>
            </>
        );
    };

    const actionBodyTemplate = (data) => {
        return (
            <>
                {sessionInformation?.role_id === 1 && (
                    <span className={`customer-badge edit`}>
                        <Link to={`/payouts/edit/${data.id}`}>Edit</Link>
                    </span>
                )}
                {data.status === "Requested" && (
                    <span className={`customer-badge edit`}>
                        <Button
                            label="Delete"
                            onClick={() => {
                                setDisplayConfirmation(true);
                                setPayoutId(data.id);
                            }}
                            className="p-button-link"
                        />
                    </span>
                )}
            </>
        );
    };

    useEffect(() => {
        setIsLoading(true);
        const fetchPayouts = async () => {
            let payload = {};
            if (sessionInformation?.role_id === 1) {
                payload.role_type = "Admin";
            } else {
                payload.user_id = sessionInformation?.id;
            }
            const payoutList = await getAll(payload);
            setPayouts(payoutList);
            setIsLoading(false);
        };
        // return () => {
        //     cleanup
        // }
        fetchPayouts();
        fetchImagesCount();
    }, []);

    const handleCancelConfirmation = () => {
        setDisplayConfirmation(false);
    };

    const handleCancelPayout = () => {
        setDisplayPayout(false);
    };

    const fetchImagesCount = async () => {
        setIsLoading(true);
        const records = await getImagesCount(sessionInformation?.id, sessionInformation?.role_id);

        if (records) {
            if (records?.totalImages) {
                setTotalImagesCount(records?.totalImages.length * 10);
            }
            setImagesCount(records?.records);
            setValues({
                ...values,
                amount: records?.records.length * 10,
            });
            return true;
        }
        setIsLoading(false);
    };

    const calculatePayouts = async () => {
        if (window.confirm("Are you sure you want to request for payout?")) {
            setIsLoading(true);
            const fetched = await fetchImagesCount();
            if (fetched) {
                setDisplayPayout(true);
            }
            setIsLoading(false);
        }
    };

    const handlePayout = async () => {
        // Do SUbmitsssion
        // if (id) {
        //     const updatedRecord = await update(id, values);
        //     setValues(updatedRecord);
        //     if (updatedRecord.status === false) {
        //         showError(updatedRecord?.message);
        //     } else {
        //         showSuccess("Payout request updated succssfully");
        //     }
        //     // console.log(updatedRecord);
        //     // handleSnackBarAndRedirect(updatedRecord.message, updatedRecord.status);
        // } else {
        if (sessionInformation?.min_amount && Number(sessionInformation?.min_amount) > values?.amount) {
            if (imagesCount > 0) {
                setIsLoading(true);
                const payload = {
                    status: "Requested",
                    role_type: sessionInformation?.role_id === 3 ? "Reporter" : sessionInformation?.role_id === 4 ? "Processor" : null,
                    user_id: sessionInformation?.id.toString(),
                    reference_number: null,
                    amount: values?.amount,
                    imagesCount: imagesCount.length,
                    imageIds: imagesCount.map((image) => {
                        return image.id;
                    }),
                };
                const addedRecord = await add(payload);
                if (addedRecord.status === false) {
                    showError(addedRecord?.message);
                    setDisplayPayout(false);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    showSuccess("Payout has been requested.");
                    setDisplayPayout(false);
                    window.location.reload();
                }
            } else {
                alert("No new images processed.");
                setDisplayPayout(false);
            }
        } else {
            alert("You cannot process the amount. Minimum balance required is " + sessionInformation?.min_amount);
            setDisplayPayout(false);
        }
        // }
    };

    const requestPayoutTemplate = () => {
        return (
            <Fragment>
                {sessionInformation?.min_amount && <h4>Minimum Balance : {sessionInformation?.min_amount}</h4>}
                <h4>Processed Images: {imagesCount.length}</h4>
                {sessionInformation?.min_amount ? <h4>Total Amount: {Number(sessionInformation?.min_amount) < values?.amount ? 0 : values?.amount - Number(sessionInformation?.min_amount)}</h4> : <h4>Total Amount: {values?.amount}</h4>}
            </Fragment>
        );
    };

    const handleConfirmation = async () => {
        const isDeleted = await _delete(payoutId);
        if (isDeleted) {
            const findDeletedIndex = payouts.findIndex((payout) => payout.id === payoutId);
            payouts.splice(findDeletedIndex, 1);
            setPayoutId("");
            showSuccess("Record deleted successfully");
        } else {
            showError("Something went wrong.");
        }
        setDisplayConfirmation(false);
    };
    return (
        <div className="grid table-demo">
            <Toast ref={toast} className="ToastMessage" />
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <div className="col-12">
                <div className="card">
                    {sessionInformation?.role_id !== 1 && <Button onClick={calculatePayouts} label="Request Payout" className="mb-3" />}
                    <div className="grid mb-2">
                        <div className="col-12 lg:col-6 xl:col-4">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Total Redeemed Points</span>
                                        <div className="text-900 font-medium text-xl">{totalImagesCount}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                        <i className="pi pi-shopping-cart text-blue-500 text-xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-4">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Redeemed Points</span>
                                        <div className="text-900 font-medium text-xl">{totalImagesCount - imagesCount?.length * 10}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                        <i className="pi pi-map-marker text-orange-500 text-xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-6 xl:col-4">
                            <div className="card mb-0">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-500 font-medium mb-3">Balance Points</span>
                                        <div className="text-900 font-medium text-xl">{imagesCount ? imagesCount?.length * 10 : 0}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                        <i className="pi pi-inbox text-cyan-500 text-xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />
                    {/* <h5>Customized</h5>
                    <p>
                        Scrollable table with gridlines (<mark>.p-datatable-gridlines</mark>), striped rows (<mark>.p-datatable-striped</mark>) and smaller paddings (<mark>p-datatable-sm</mark>).
                    </p> */}
                    <DataTable
                        value={payouts}
                        scrollable
                        scrollHeight="600px"
                        className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                        rows={10}
                        dataKey="id"
                        paginator
                        rowHover
                        globalFilter={globalFilter}
                        emptyMessage="No payouts list found."
                        loading={isLoading}
                        header={tableHeader}
                    >
                        <Column field="id" header="ID" sortable body={bodyTemplate}></Column>
                        <Column field="amount" header="Amount" sortable body={bodyTemplate}></Column>
                        <Column field="reference_number" header="Reference Number" sortable body={bodyTemplate}></Column>
                        <Column field="createdAt" header="Created At" sortable body={bodyTemplate}></Column>
                        <Column field="status" header="Status" sortable body={statusBodyTemplate}></Column>
                        <Column field="status" header="Actions" sortable body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            {displayConfirmation && <ConfirmationDialogBox handleCancelConfirmation={handleCancelConfirmation} handleConfirmation={handleConfirmation} displayConfirmation={displayConfirmation} />}
            {displayPayout && (
                <ConfirmationDialogBox handleCancelConfirmation={handleCancelPayout} handleConfirmation={handlePayout} displayConfirmation={displayPayout} headerContent="Request Payout" mainContent={imagesCount.length > 0 ? requestPayoutTemplate() : "No new images processed from your end."} />
            )}
        </div>
    );
};

export default Payouts;
