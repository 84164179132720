import axios from "axios";
import { Redirect } from "react-router";

class RestClient {
    async get(url, params = {}) {
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            return await axios.get(`${url}`, {
                headers: {
                    Authorization: userInfo?.token ? "Bearer " + userInfo?.token : "",
                },
                params,
            });
        } catch (err) {
            const { data, status } = err;
            if (status === 200) {
                return data;
            } else {
                if (data?.message === "Unauthorized") {
                    sessionStorage.clear();
                    window.location = "/";
                } else {
                    return { status: false, message: data?.message };
                }
            }
        }
        // return new Promise((resolve, reject) => {
        //   axios
        //     .get(`${url}`)
        //     .then((response) => {
        //       resolve({ status: response.status, data: response.data });
        //     })
        //     .catch((error) => {
        //       reject(error);
        //     });
        // });
    }

    async post(url, params) {
        const lastUrl = url.substring(url.lastIndexOf("/") + 1);
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

        let response = {};
        try {
            if (lastUrl === "authenticate" || lastUrl === "register") {
                response = await axios.post(`${url}`, params);
            } else {
                response = await axios.post(`${url}`, params, {
                    headers: {
                        Authorization: userInfo?.token ? "Bearer " + userInfo?.token : "",
                    },
                });
            }
            return response;
        } catch (err) {
            const { data, status } = err;
            if (status === 200) {
                return data;
            } else {
                if (data?.message === "Unauthorized") {
                    sessionStorage.clear();
                    window.location = "/";
                } else {
                    return { status: false, message: data?.message };
                }
            }

            // throw new Error(err.response.data.message);
        }
    }

    async put(url, params) {
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            const response = await axios.put(`${url}`,
            params, {
                headers: {
                    Authorization: userInfo?.token ? "Bearer " + userInfo?.token : "",
                },
            });
            return response;
        } catch (err) {
            const { data, status } = err;
            if (status === 200) {
                return data;
            } else {
                if (data?.message === "Unauthorized") {
                    sessionStorage.clear();
                    window.location = "/";
                } else {
                    return { status: false, message: data?.message };
                }
            }
        }
    }

    async delete(url, params = {}) {
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        try {
            const response = await axios.delete(`${url}`, {
                headers: {
                    Authorization: userInfo?.token ? "Bearer " + userInfo?.token : "",
                },
            });
            return response;
        } catch (err) {
            const { data, status } = err;
            if (status === 200) {
                return data;
            } else {
                if (data?.message === "Unauthorized") {
                    sessionStorage.clear();
                    window.location = "/";
                } else {
                    return { status: false, message: data?.message };
                }
            }
        }
    }

    // async post(url:string, params: {}) {
    //   return new Promise((resolve, reject)=>{
    //     axios.post(`${url}`)
    //   })
    // }
}

export default RestClient;
