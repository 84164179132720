import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const getAllDevices = async () => {
    const result = await restClient.get(`${BASE_URL}/devices`, []);
    return result?.data;
};

export const addDevice = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/devices/create`, payload);
    return result?.data;
};

export const updateDevice = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/devices/${id}`, payload);
    return result?.data;
};

export const deleteDevice = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/devices/${id}`);
    return result?.data;
};

export const getDevice = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/devices/${payload}`);
    return result?.data;
};

export const getUserDevice = async (userId) => {
    const result = await restClient.get(`${BASE_URL}/devices/currentuser`, { userId });
    return result?.data;
};

export const validateDevice = async (serialNumber) => {
    const result = await restClient.get(`${BASE_URL}/devices/validateDevice`, { serialNumber });
    return result?.data;
};
