import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { doLogin } from "./auth.service";
import { ProgressSpinner } from "primereact/progressspinner";
import { SuccessToastConfig, ErrorToastConfig } from "utils/ToastConstants";
import { Toast } from "primereact/toast";
import { getRoleByName } from "pages/Roles/roles.service";
import { addUser } from "pages/Users/users.service";

const initialVales = {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    password: "",
    email: "",
    dob: "",
    role_id: "",
    pancard: "",
    aadhar: "",
    status: "Active",
};

const Signup = () => {
    const history = useHistory();
    const { id } = useParams();
    const [values, setValues] = useState(initialVales);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    useEffect(() => {
        const fetchRole = async () => {
            const fetchedRole = await getRoleByName({ name: id });
            if (fetchedRole.length > 0) {
                setValues({
                    ...values,
                    role_id: fetchedRole[0]?.id,
                });
            } else {
                showError("You cannot register as role has been disabled by Admin");
            }
        };

        fetchRole();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const payload = values;
        const response = await addUser(payload);
        if (response.status === false) {
            setIsLoading(false);
            showError(response.message);
           
            // window.location = "/dashboard";
        } else {
            setIsLoading(false);
            showSuccess("Registration Successful");
            setTimeout(() => {
                history.push("/");
            }, 500);
        }
    };
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="card p-fluid">
                <form onSubmit={handleSubmit} autoComplete="off">
                    <h5>Register as {id}</h5>
                    <div className="field">
                        <label htmlFor="firstName">First Name*</label>
                        <InputText id="firstName" name="firstName" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Firstname" />
                    </div>
                    <div className="field">
                        <label htmlFor="middleName">Middle Name*</label>
                        <InputText id="middleName" name="middleName" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Middlename" />
                    </div>
                    <div className="field">
                        <label htmlFor="lastName">Last Name*</label>
                        <InputText id="lastName" name="lastName" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Last Name" />
                    </div>
                    <div className="field">
                        <label htmlFor="email">Email*</label>
                        <InputText id="email" name="email" type="email" required={true} onChange={(e) => handleInputChange(e)} placeholder="Email" />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password</label>
                        <InputText type="password" name="password" id="password" required={true} onChange={(e) => handleInputChange(e)} placeholder="Password" />
                    </div>
                    <div className="flex">
                        <Button label="Register" disabled={!values?.role_id} />
                        <Button type="button" onClick={()=>{history.push("/")}} className="ml-5 p-button-link" label="If already registered, please Login" />
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default Signup;
