import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const getAll = async () => {
    const result = await restClient.get(`${BASE_URL}/batches`, []);
    return result?.data;
};

export const add = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/batches/create`, payload);
    return result?.data;
};

export const update = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/batches/${id}`, payload);
    return result?.data;
};

export const deleteRecord = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/batches/${id}`);
    return result?.data;
};

export const getSingle = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/batches/${payload}`);
    return result?.data;
};

export const getByName = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/batches/`, payload);
    return result?.data;
};

export const fetchProcessedDataByBatchID = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/processeddata/`, payload);
    return result?.data;
};
export const getProcessors = async () => {
    const result = await restClient.get(`${BASE_URL}/users/getProcessors`, []);
    return result?.data;
};

export const getUserBatches = async (userId) => {
    const result = await restClient.get(`${BASE_URL}/batches/userBatchList/${userId}`, []);
    return result?.data;
};
