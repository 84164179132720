import { update } from "pages/Batches/batches.service";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ConfirmationDialogBox from "utils/ConfirmationDialogBox";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { checkIfBatchAssigned } from "./processorDashboard.service";

const ProcessorDashboard = () => {
    // Fetch Assigned Batch
    const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
    const [isLoading, setIsLoading] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [batch, setBatch] = useState({});
    const history = useHistory();
    useEffect(() => {
        if (sessionInformation?.id && sessionInformation?.training_completed === "yes" && sessionInformation?.assessment_cleared === "yes" ) {
            setIsLoading(true);
            // Check If any batch is assigned to user, if assigned open popup
            const fetchAssignedBatch = async () => {
                const isBatchAssigned = await checkIfBatchAssigned(sessionInformation?.id);
                console.log("isBatchAssigned",isBatchAssigned);
                setIsLoading(false);
                setBatch(isBatchAssigned);
                if (isBatchAssigned && !isBatchAssigned?.accepted_flag && isBatchAssigned?.status === "Acceptance Pending") {
                    setDisplayConfirmation(true);
                }
            };
            fetchAssignedBatch();
        }
    }, []);

    const toast = useRef();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const handleConfirmation = async () => {
        setIsLoading(true);
        const batchInformation = { ...batch };
        if (batchInformation) {
            batchInformation.accepted_flag = true;
            batchInformation.status = "Accepted";
            const updatedRecord = await update(batchInformation.id, batchInformation);
            setIsLoading(false);
            if (updatedRecord.status === false) {
                showError(updatedRecord?.message);
            } else {
                showSuccess("Batch accepted successfully");
                history.push("/annotation");
            }
        } else {
            setIsLoading(false);
        }
        setDisplayConfirmation(false);
    };

    const handleCancelConfirmation = async () => {
        setIsLoading(true);
        const batchInformation = { ...batch };
        if (batchInformation) {
            batchInformation.accepted_flag = false;
            batchInformation.status = "Rejected";
            const updatedRecord = await update(batchInformation.id, batchInformation);
            setIsLoading(false);
            if (updatedRecord.status === false) {
                showError(updatedRecord?.message);
            } else {
                showError("You have rejected the batch.");
            }
        } else {
            setIsLoading(false);
        }
        setDisplayConfirmation(false);
    };

    return (
        <Fragment>
            <Toast ref={toast} className="ToastMessage" />
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            {displayConfirmation && (
                <ConfirmationDialogBox handleCancelConfirmation={handleCancelConfirmation} handleConfirmation={handleConfirmation} displayConfirmation={displayConfirmation} headerContent="Accept Batch" mainContent="Batch has been assigned to you. Please accept to proceed the Annotation." />
            )}
            <div className="card p-fluid">Dashboard</div>
        </Fragment>
    );
};

export default ProcessorDashboard;
