import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { statusList } from "utilities/utility.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { addRole, getRole, updateRole } from "../roles.service";

const initialVales = {
    id: "",
    name: "",
    status: "",
};

const AddRole = () => {
    const { id } = useParams();
    const [values, setValues] = useState(initialVales);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const history = useHistory();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    useEffect(() => {
        const fetchRole = async () => {
            setIsLoading(true);
            const record = await getRole(id);
            setValues(record);
            setIsLoading(false);
        };
        if (id) {
            fetchRole();
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (id) {
            const updatedRecord = await updateRole(id, values);
            setValues(updatedRecord);
            if (updatedRecord.status === false) {
                showError(updatedRecord?.message);
            } else {
                showSuccess("Record updated succssfully");
            }
            // console.log(updatedRecord);
            // handleSnackBarAndRedirect(updatedRecord.message, updatedRecord.status);
        } else {
            const addedRecord = await addRole(values);
            if (addedRecord.status === false) {
                showError(addedRecord?.message);
            } else {
                showSuccess("Record added succssfully");
            }
            history.push("/roles");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const statuses = statusList();
    const { name, status } = values;
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="mb-4">
                <Link to="/roles">
                    <Button label="Back" className="p-button-sm p-button-success" />
                </Link>
            </div>
            <form method="POST" onSubmit={handleSubmit} autoComplete="Off">
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card p-fluid">
                            <h5>{id ? "Edit Role" : "Add Role"}</h5>
                            <div className="field">
                                <label htmlFor="firstname">Role Tye*</label>
                                <InputText id="firstname" name="name" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Role Type" value={name} />
                            </div>

                            <div className="field">
                                <label htmlFor="status">Status*</label>
                                <Dropdown id="status" name="status" value={values?.status} options={statuses} optionLabel="status" optionValue="status" placeholder="Select Status" required={true} onChange={(e) => handleInputChange(e)} value={status}></Dropdown>
                            </div>
                            <div>
                                <Button label="Save" type="submit" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default AddRole;
