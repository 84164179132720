import RestClient from "RestClient";

const restClient = new RestClient();

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const getAll = async () => {
    const result = await restClient.get(`${BASE_URL}/chapters`, []);
    return result?.data;
};

export const add = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/chapters/create`, payload);
    return result?.data;
};

export const update = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/chapters/${id}`, payload);
    return result?.data;
};

export const deleteRecord = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/chapters/${id}`);
    return result?.data;
};

export const getSingle = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/chapters/${payload}`);
    return result?.data;
};

export const getByName = async(payload) => {
    const result = await restClient.get(`${BASE_URL}/chapters/`,payload);
    return result?.data;
}