import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { statusList } from "utilities/utility.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { add, getSingle, update } from "../topics.service";
import { getAll as moduleList } from "../../Modules/modules.service";
import { getAll as chapterList } from "../../Chapters/chapters.service";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { getExtension } from "utilities/utility.service";

const initialVales = {
    id: "",
    name: "",
    description: "",
    module_id: "",
    chapter_id: "",
    status: "",
    optionsPath: {},
    uniqueNumber: "",
    associated_image: "",
};

const AddTopic = () => {
    const { id } = useParams();
    const [values, setValues] = useState(initialVales);
    const [isLoading, setIsLoading] = useState(false);
    const [modules, setModules] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [filteredChapters, setFilteredChapters] = useState([]);
    const toast = useRef();
    const history = useHistory();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    useEffect(() => {
        const fetchCurrentRecord = async () => {
            setIsLoading(true);
            const record = await getSingle(id);
            setValues(record);
            const chapters = await chapterList();
            setChapters(chapters);
            if (chapters && chapters.length > 0) {
                const filtered = chapters.filter((chapter) => chapter.module_id === record.module_id);
                setFilteredChapters(filtered);
            }
            setIsLoading(false);
        };

        const fetchModules = async () => {
            setIsLoading(true);
            const list = await moduleList();
            setModules(list);
            const chapters = await chapterList();
            setChapters(chapters);
            setIsLoading(false);
        };
        if (id) {
            fetchCurrentRecord();
        } else {
            setValues({ ...values, uniqueNumber: Date.now() });
        }
        fetchModules();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        setIsLoading(true);
        const { uniqueNumber } = values;
        let associatedImagesArray = [];
        if (typeof values?.associated_image !== "string") {
            let count = 5;
            for (const key of Object.keys(values?.associated_image)) {
                formData.append("images", values?.associated_image[key], `IMG_TRAINING_MATERIAL_${uniqueNumber}_${count}.${getExtension(values?.associated_image[key].name)}`);
                associatedImagesArray.push(`IMG_TRAINING_MATERIAL_${uniqueNumber}_${count}.${getExtension(values?.associated_image[key].name)}`);
                ++count;
            }
        }

        formData.append("name", values?.name);
        formData.append("description", values?.description);
        formData.append("module_id", values?.module_id);
        formData.append("chapter_id", values?.chapter_id);
        formData.append("status", values?.status);
        if (associatedImagesArray.length > 0) {
            formData.append("associated_image", associatedImagesArray.toString());
        }

        formData.append("uniqueNumber", uniqueNumber);

        if (id) {
            const updatedRecord = await update(id, formData);
            setValues(updatedRecord);
            if (updatedRecord.status === false) {
                setIsLoading(false);
                showError(updatedRecord?.message);
            } else {
                setIsLoading(false);
                showSuccess("Record updated succssfully");
            }
        } else {
            const addedRecord = await add(formData);
            if (addedRecord.status === false) {
                setIsLoading(false);
                showError(addedRecord?.message);
            } else {
                setIsLoading(false);
                showSuccess("Record added succssfully");
            }
            history.push("/topics");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const multipleUploader = (event) => {
        if (event.files.length > 4) {
            // event.files.preventDefault();
            showError("You can upload only 4 Files");
            alert("You can upload only 4 Files");
            event.options.clear()
        } else {
            setValues({
                ...values,
                associated_image: event.files,
            });
        }
    };

    // const onTemplateSelect = (e) => {
    //     if(e.files.length > 4) {
    //         e.preventDefault();
    //     }
    //     return true;
    // };

    const handleModuleChange = (moduleId) => {
        setFilteredChapters([]);
        const filtered = chapters.filter((chapter) => chapter.module_id === moduleId);
        setFilteredChapters(filtered);
        // filteredChapters
    };

    const statuses = statusList();
    const { name, status, module_id, chapter_id, description } = values;
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="mb-4">
                <Link to="/topics">
                    <Button label="Back" className="p-button-sm p-button-success" />
                </Link>
            </div>
            <form method="POST" onSubmit={handleSubmit} autoComplete="Off">
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card p-fluid">
                            <h5>{id ? "Edit Topic" : "Add Topic"}</h5>
                            <div className="field">
                                <label htmlFor="status">Select Module*</label>
                                <Dropdown
                                    id="module_id"
                                    name="module_id"
                                    options={modules}
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Select Module"
                                    required={true}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        handleModuleChange(e.target.value);
                                    }}
                                    value={module_id}
                                ></Dropdown>
                            </div>
                            <div className="field">
                                <label htmlFor="chapter">Select Chapter*</label>
                                <Dropdown id="chapter_id" name="chapter_id" options={filteredChapters} optionLabel="name" optionValue="id" placeholder="Select Chapter" required={true} onChange={(e) => handleInputChange(e)} value={chapter_id}></Dropdown>
                            </div>
                            <div className="field">
                                <label htmlFor="name">Topic Name*</label>
                                <InputText id="name" name="name" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Topic Name" value={name} />
                            </div>
                            <div className="field">
                                <label htmlFor="description">Topic Description*</label>
                                <InputTextarea id="description" name="description" required={true} onChange={(e) => handleInputChange(e)} placeholder="Topic Description" value={description} />
                            </div>

                            <div className="field">
                                <label htmlFor="associated_image">Associated Image</label>

                                {values?.optionsPath && values?.optionsPath?.associated && (
                                    <div className="flex space-between">
                                        {values?.optionsPath?.associated.map((assoc) => {
                                            return <img className="mr-2 mb-4" src={assoc} style={{ width: "auto", height: "100px" }} />;
                                        })}
                                    </div>
                                )}
                                {/* <InputText id="associated_image" name="associated_image" type="text" onChange={(e) => handleInputChange(e)} placeholder="Associated Image" value={values?.associated_image} /> */}
                                <FileUpload name="assoicated_image" multiple customUpload uploadHandler={(event) => multipleUploader(event)} auto accept="image/*" />
                            </div>

                            <div className="field">
                                <label htmlFor="status">Status*</label>
                                <Dropdown id="status" name="status" options={statuses} optionLabel="status" optionValue="status" placeholder="Select Status" required={true} onChange={(e) => handleInputChange(e)} value={status}></Dropdown>
                            </div>
                            <div>
                                <Button label="Save" type="submit" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default AddTopic;
