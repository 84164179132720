import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { doLogin } from "./auth.service";
import { storeLoggedInformation } from "./authSlice";
import { ProgressSpinner } from "primereact/progressspinner";
import { SuccessToastConfig, ErrorToastConfig } from "utils/ToastConstants";
import { Toast } from "primereact/toast";

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();

    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "email") {
            setEmail(value);
        }
        if (name === "password") {
            setPassword(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const payload = { email, password };
        const response = await doLogin(payload);
        if (response.status === true) {
            setIsLoading(false);
            showSuccess("Login Successful");
            sessionStorage.setItem("userInfo", JSON.stringify(response.result));
            dispatch(storeLoggedInformation(response.result));
            if (response.result.role_id === 1) {
                history.push("/dashboard");
            } else {
                history.push("/");
            }
            // window.location = "/dashboard";
        } else {
            setIsLoading(false);
            showError(response.message);
        }
    };
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="card p-fluid">
                <form onSubmit={handleSubmit} autoComplete="off">
                    <h5>Login</h5>
                    <div className="field">
                        <label htmlFor="email">Email *</label>
                        <InputText name="email" placeholder="Email" id="email" type="text" required={true} onChange={(e) => handleInputChange(e)} value={email} />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Password*</label>
                        <InputText name="password" placeholder="Password" id="password" type="password" required={true} onChange={(e) => handleInputChange(e)} value={password} />
                    </div>
                    <Button label="Login" />
                    {/* <div className="flex mt-3">
                        <div className="mr-auto">
                            <Link to="/signup/reporter" className="p-button-link">
                                Register as Reporter
                            </Link>
                        </div>
                        <div>
                            <Link to="/signup/processor" className="p-button-link">
                                Register as Processor
                            </Link>
                        </div>
                    </div> */}
                </form>
            </div>
        </Fragment>
    );
};

export default Login;
