import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { statusList } from "utilities/utility.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { addDevice, getDevice, updateDevice } from "../devices.service";

const initialVales = {
    id: "",
    mac_id: "",
    serial_number: "",
    description: "",
    device_code: "",
    device_make: "",
    assigned_to: "",
    status: "",
};

const AddDevice = () => {
    const { id } = useParams();
    const [values, setValues] = useState(initialVales);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const history = useHistory();

    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        if (id) {
            const updatedRecord = await updateDevice(id, values);
            if (updatedRecord.status === false) {
                showError(updatedRecord?.message);
            } else {
                setValues(updatedRecord);
                showSuccess("Record updated succssfully");
            }
            // console.log(updatedRecord);
            // handleSnackBarAndRedirect(updatedRecord.message, updatedRecord.status);
        } else {
            const addedRecord = await addDevice(values);
            if (addedRecord.status === false) {
                showError(addedRecord?.message);
            } else {
                showSuccess("Record added succssfully");
            }
            history.push("/devices");
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const fetchDevice = async () => {
            setIsLoading(true);
            let record = await getDevice(id);
            setValues(record);
            setIsLoading(false);
        };
        if (id) {
            fetchDevice();
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const statuses = statusList();
    const { mac_id, serial_number, description, device_code, device_make, assigned_to, status } = values;
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="mb-4">
                <Link to="/devices">
                    <Button label="Back" className="p-button-sm p-button-success" />
                </Link>
            </div>
            <form method="POST" onSubmit={handleSubmit} autoComplete="Off">
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card p-fluid">
                            <h5>{id ? "Edit Device" : "Add Device"}</h5>

                            <div className="field">
                                <label htmlFor="serial_number">Serial Number*</label>
                                <InputText id="serial_number" name="serial_number" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Serial Number" value={serial_number} />
                            </div>
                            <div className="field">
                                <label htmlFor="mac_id">Mac ID*</label>
                                <InputText id="mac_id" name="mac_id" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Mac ID" value={mac_id} />
                            </div>

                            <div className="field">
                                <label htmlFor="description">Description*</label>
                                <InputTextarea id="description" name="description" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Description" value={description} />
                            </div>
                            <div className="field">
                                <label htmlFor="device_code">Device Code*</label>
                                <InputText id="device_code" name="device_code" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Device Code" value={device_code} />
                            </div>
                            <div className="field">
                                <label htmlFor="device_make">Device Make*</label>
                                <InputText id="device_make" name="device_make" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Device Make" value={device_make} />
                            </div>
                            <div className="field">
                                <label htmlFor="status">Status*</label>
                                <Dropdown id="status" name="status" value={values?.status} options={statuses} optionLabel="status" optionValue="status" placeholder="Select Status" required={true} onChange={(e) => handleInputChange(e)} value={status} />
                            </div>
                            <div>
                                <Button label="Save" type="submit" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default AddDevice;
