import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { payoutStatusList } from "utilities/utility.service";
import { ErrorToastConfig } from "utils/ToastConstants";
import { SuccessToastConfig } from "utils/ToastConstants";
import { add, getImagesCount, getSingle, update } from "../payouts.service";

const initialVales = {
    id: "",
    amount: null,
    reference_number: null,
    role_type: null,
    user_id: null,
    status: null,
};

const AddPayouts = () => {
    const { id } = useParams();

    const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));

    const [values, setValues] = useState(initialVales);
    const [imagesCount, setImagesCount] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const history = useHistory();
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    useEffect(() => {
        const fetchPayout = async () => {
            setIsLoading(true);
            const record = await getSingle(id);
            setValues(record);
            setIsLoading(false);
        };

        const fetchImagesCount = async () => {
            setIsLoading(true);
            const records = await getImagesCount(sessionInformation?.id, sessionInformation?.role_id);
            if (records) {
                setImagesCount(records);
                setValues({
                    ...values,
                    amount: records.length * 10,
                });
            }
            setIsLoading(false);
        };

        if (id) {
            fetchPayout();
        } else {
            if (sessionInformation?.role_id === 3) {
                setValues({
                    ...values,
                    role_type: "Reporter",
                });
            }
            if (sessionInformation?.role_id === 4) {
                setValues({
                    ...values,
                    role_type: "Processor",
                });
            }
            setValues({
                ...values,
                user_id: "sessionInformation?.id",
                status: "Requested",
            });
        }
        fetchImagesCount();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (id) {
            setIsLoading(true);
            const payload = { ...values, user_id: values?.user_id.toString() };
            const updatedRecord = await update(id, payload);
            setValues(updatedRecord);
            if (updatedRecord.status === false) {
                setIsLoading(false);
                showError(updatedRecord?.message);
            } else {
                setIsLoading(false);
                showSuccess("Payout request updated succssfully");
            }
            // console.log(updatedRecord);
            // handleSnackBarAndRedirect(updatedRecord.message, updatedRecord.status);
        } else {
            const addedRecord = await add(values);
            if (addedRecord.status === false) {
                showError(addedRecord?.message);
            } else {
                showSuccess("Payout has been requested.");
            }
            history.push("/payouts");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const statuses = payoutStatusList();
    const { reference_number, amount, status } = values;
    return (
        <Fragment>
            {isLoading && (
                <div className="spinner inner-spinner">
                    <ProgressSpinner />
                </div>
            )}
            <Toast ref={toast} className="ToastMessage" />
            <div className="mb-4">
                <Link to="/payouts">
                    <Button label="Back" className="p-button-sm p-button-success" />
                </Link>
            </div>
            <form method="POST" onSubmit={handleSubmit} autoComplete="Off">
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card p-fluid">
                            <h5>{id ? "Requested Payout" : "Request Payout"}</h5>
                            <div className="field">
                                <label htmlFor="processedCount">Total Images Processed*</label>
                                <InputText id="processedCount" disabled name="" type="text" placeholder="Processed Count" value={values?.imagesCount} />
                            </div>
                            <div className="field">
                                <label htmlFor="amount">Amount*</label>
                                <InputText id="amount" disabled name="amount" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Amount" value={amount} />
                            </div>

                            <div className="field">
                                <label htmlFor="description">Reference Number*</label>
                                <InputTextarea id="reference_number" name="reference_number" type="text" required={true} onChange={(e) => handleInputChange(e)} placeholder="Reference Number" value={reference_number} required={true} />
                            </div>
                            {sessionInformation?.role_id === 1 && (
                                <div className="field">
                                    <label htmlFor="status">Status*</label>
                                    <Dropdown id="status" name="status" value={values?.status} options={statuses} optionLabel="status" optionValue="status" placeholder="Select Status" required={true} onChange={(e) => handleInputChange(e)} value={status}></Dropdown>
                                </div>
                            )}
                            <div>
                                <Button label="Save" type="submit" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default AddPayouts;
